export default [
  {
    path: '/',
    name: 'default',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/packinglist/default'),
  },
  {
    path: '/configuration/organisation',
    name: 'organisation',
    meta: {
      authRequired: true,
      rolesRequired: "admin"
    },
    component: () => import('./views/configuration/organisation/default'),
  },    
  {
    path: '/configuration/users',
    name: 'users',
    meta: {
      authRequired: true,
      rolesRequired: "admin"
    },
    component: () => import('./views/configuration/users/default'),
  },     
  {
    path: '/configuration/users/:userId',
    name: 'editUser',
    meta: {
      authRequired: true,
      rolesRequired: "admin"
    },
    component: () => import('./views/configuration/users/userDetail'),
  },
  {
    path: '/:workorderid/',
    name: 'products',
    meta: {
      authRequired: true,
    },
    props: route => ({query: route.query.packing}),
    component: () => import('./views/products/default'),
  },
]
