import { PublicClientApplication } from '@azure/msal-browser';
import axios from "axios";
import i18n from './i18n';
import Router from './router'

export default class MSALWrapper {
    
  authConfig = {
      auth: {
        clientId: '60f24fb5-3365-4dfa-a311-9d3ef1739dd1',
        authority: 'https://webcab.b2clogin.com/tfp/webcab.onmicrosoft.com/B2C_1_Signin',
        knownAuthorities: ['https://webcab.b2clogin.com']
    },
    cache: {
      cacheLocation: 'sessionStorage'
    }
  };

  accountId = "";
  currentAccount = null;

  accessToken = "";
  idToken = "";
  msalInstance = {};

  scopes = ["https://webcab.onmicrosoft.com/8da2c2af-4077-406c-ba3d-16b698823fb9/access"]

  tokenRequest = {
    scopes: this.scopes
  }

  loginRequest = {
    scopes: this.scopes,
    extraQueryParameters: {ui_locales: i18n.locale}
  }

  passwordResetRequest = {
    scopes: this.scopes,
    authority: 'https://webcab.b2clogin.com/tfp/webcab.onmicrosoft.com/B2C_1_PasswordReset',
    extraQueryParameters: {ui_locales: i18n.locale}
  }
  constructor(redirectUrl) {
      this.authConfig.auth.redirectUri = redirectUrl;
      this.msalInstance = new PublicClientApplication(this.authConfig);
  }
  
  async init(){
    let promise = new Promise(async (accept, reject) => {
      this.msalInstance.handleRedirectPromise().then(async (response) => {
        await this.handleResponse(response);
        accept();
      }, async (response) => {
        if(response.errorMessage.includes("AADB2C90118")) {
          this.msalInstance.loginRedirect(this.passwordResetRequest);
        } else if(response.errorMessage.includes("AADB2C90091")){ // password reset cancelled by user
          this.msalInstance.loginRedirect(this.loginRequest);
        }
        reject();
      });
    });

    return promise;
  }

  async getOrganisationApiToken() {
    let currentAccounts = this.msalInstance.getAllAccounts();
    let request = {
      scopes: ["https://webcab.onmicrosoft.com/348aabfb-cce8-42b8-b603-4668b98102d7/googleauth"],
      account: currentAccounts
    }

    let promise = this.msalInstance.acquireTokenSilent(request).then((response) => {
      return response.accessToken;
    }, () => {
    });

    return promise;
  }
  
  async getToken() {
      let currentAccounts = this.msalInstance.getAllAccounts();
      let request = {
        scopes: this.scopes,
        account: currentAccounts
      }
  
      let promise = this.msalInstance.acquireTokenSilent(request).then((response) => {
        const currentAccounts = this.msalInstance.getAllAccounts();
        this.accountId = currentAccounts[0].homeAccountId;
        this.currentAccount = currentAccounts[0];
        this.accessToken = response.accessToken;
        
        return response.accessToken;
      }, () => {
        //error send to log in
        this.msalInstance.loginRedirect(this.loginRequest);
      });
  
      return promise;
  }

  setTokenResponse(response){
    const currentAccounts = this.msalInstance.getAllAccounts();
    this.accountId = currentAccounts[0].homeAccountId;
    this.currentAccount = currentAccounts[0];
    this.accessToken = response.accessToken;
    this.idToken = response.idToken;

    
    Object.assign(axios.defaults, {headers: {authorization: "Bearer " + this.accessToken}});
  }

  SignIn = () => {
      this.msalInstance.loginRedirect(this.loginRequest)
  }

  SignOut = async () => {
      let logoutRequest = {
        account: this.msalInstance.getAccountByHomeId(this.accountId)
      }
      this.msalInstance.logoutRedirect(logoutRequest);
  }

  ResetPassword = () => {
    this.msalInstance.loginRedirect(this.passwordResetRequest);
  }

  handleResponse = async (response) => {
    if (response !== null) {
        this.setTokenResponse(response);
        Router.replace('/')
    } else {
        // In case multiple accounts exist, you can select
        const currentAccounts = this.msalInstance.getAllAccounts();
    
        if (currentAccounts.length === 0) {
          // no accounts signed-in, attempt to sign a user in
          await this.msalInstance.loginRedirect(this.loginRequest);
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
            alert('Multiple user accounts detected, please clear cache(ctrl + shift + del) and log in again.')
        } else if (currentAccounts.length === 1) {       
          let request = this.msalInstance.SilentRequest = {
            scopes: this.scopes,
            account: currentAccounts
          };
          let promise = this.msalInstance.acquireTokenSilent(request).then(async (response) => {
            this.setTokenResponse(response);
          }, async () => {
            //error send to log in
            await this.msalInstance.loginRedirect(this.loginRequest);
          });

          await promise;
        }
    }
  }
}
